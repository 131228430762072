//@import "~@themes/index.scss";

//@include themeMixin {
  .m-reset-passowrd-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(189, 189, 189);//themeAttrVal(v4-bg-2);
  }
  .m-reset-passowrd-inner {
    position: absolute;
    width: 600px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: themeAttrVal(v4-bg-2);
    border: 1px solid themeAttrVal(v4-border-3);
  }
  .m-reset-passowrd-title {
    /*border: solid 1px red;*/
    position: relative;
    margin: 51px 0 0;
    height: 22px;
    text-align: center;
    line-height: 20px;
  }
  .m-reset-passowrd-title-icon {
    display: inline-block;
    font-size: 24px;
    color: themeAttrVal(v4-primary-text-color);
    vertical-align: middle;
  }
  .m-reset-passowrd-title-text {
    display: inline-block;
    margin-left: 3px;
    font-size: 16px;
    line-height: 22px;
    color: themeAttrVal(v4-primary-text-color);
    vertical-align: middle;
  }
  .m-reset-passowrd-row-wrap {
    position: relative;
    width: 100%;
    margin: 65px 0px;
  }
  .m-reset-passowrd-row {
    display: flex;
    width: 100%;
    padding: 0 79px;
    margin-top: 20px;
  }
  .m-reset-passowrd-label {
    flex: 1 0 80px;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: themeAttrVal(v4-second-text-color);
    .lang-en & {
      flex: 1 0 134px;
    }
  }
  .m-reset-passowrd-input {
    flex: 0 1 100%;
    margin-left: 10px;
  }
  .m-reset-passowrd-footer {
    margin: 60px 0 0;
    text-align: center;
  }

  .m-reset-passowrd-success-mask {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
  }
  .m-reset-passowrd-success-mask.active {
    display: block;
    background: rgba(0,0,0,0.4);
  }
  .m-reset-passowrd-success {
    position: absolute;
    width: 260px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: themeAttrVal(v4-bg-1);
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.3);
    border-radius: themeAttrVal(v4-br-md);
  }
  .m-reset-passowrd-success-icon {
    position: absolute;
    top: 26px;
    left: 110px;
    font-size: 40px;
    color: themeAttrVal(v4-primary-text-color);
  }
  .m-reset-passowrd-success .m-reset-passowrd-success-text {
    margin: 97px 0 0;
    font-size: themeAttrVal(v4-fz-16);
    color: themeAttrVal(v4-primary-text-color);
    text-align: center;
  }
  .m-reset-passowrd-success-msg {
    margin: 10px 0;
    text-align: center;
  }
  .m-reset-passowrd-success-msg-inner {
    display: inline-block;
    padding: 0 5px;
    line-height: 20px;
    font-size: themeAttrVal(v4-fz-12);
    color: themeAttrVal(v4-third-text-color);
    background: themeAttrVal(v4-bg-1);
  }

  .browserDownload {
    .ace-modal-content {
      width: 510px;
    }
  }
  .f-login-dl-browser-btn {
    text-decoration-line: underline !important;
  }

  .lang-en .m-reset-passowrd-label {
    flex-basis: 150px;
  }
//}
