body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.appbg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url("../../static/images/bglogin.jpg");
  background-size: cover;
}
.loginform {
  width: 440px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  margin-bottom: 0;
  vertical-align: top;
}
.ant-form-item-control-input-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  //max-width: 100%;
}
.ant-typography {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  //font-size: 20px;
  line-height: 1.2105263157894737;
  //padding: 20px;
}
//==================================================
//@import "~@themes/index.scss";

//@include themeMixin {
.m-login-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //background: url(themeAttrVal(lgn-bg)) center center no-repeat;
  //=============> background: url("../../static/images/bglogin.jpg");
  //background-color: aliceblue;
  background-size: cover;
}
.otpheader {
  top: 21% !important;
}
.otp {
  top: 44.5% !important;
}
.setup {
  top: 55.5% !important;
}

.m-login-titlebd {
  display: inline-block;
  position: absolute;
  top: 29%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffff;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 508px;
  height: 2px;
}
.logintop {
  top: 18.5% !important;
}
.m-login {
  display: inline-block;
  position: absolute;
  top: 49.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  //padding: 0 50px 15px 20px;
  border-radius: 10px;
  background-color: #ffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.m-login-title {
  padding: 15px 50px 20px 27px;
  border-bottom: 2px solid gray;
  background-color: #ffff;
  text-align: center;
  font: 1em sans-serif;
  font-size: 16px;
  font-weight: 900;
  color: #20ce88;
  .subtitle {
    padding-top: 10px;
    text-align: center;
    font: 1em sans-serif;
    font-size: 10px;
    font-weight: 100;
    color: #000;
  }
}
.m-login-form {
  padding: 10px 20px 20px 20px;
  background-color: #ffff;
  border-radius: 10px;
  text-align: center;
  font: 1em sans-serif;
  font-size: 16px;
  button {
    margin-top: 20px;
  }
}

.m-login-content {
  width: 406px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.m-login-user {
  flex-shrink: 0;
  width: 200px;
  position: relative;
}
.m-login-input {
  padding: 0 12px 0 12px;
  width: 100%;
  height: 35px;
  line-height: 40px;
  font-size: themeAttrVal(v4-fz-14);
  color: green; //themeAttrVal(v4-white);
  border: 1 solid #8cc63f;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px 0px themeAttrVal(v4-h1-bg-1),
    inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}
.m-login-input::-webkit-input-placeholder {
  color: red; //themeAttrVal(v4-third-text-color);
}
.m-login-input:focus {
  outline: none;
  box-shadow: none;
}

.m-pwd-hide {
  display: block;
  padding: 0;
  height: 0;
  border: none;
  opacity: 0;
}
.m-login-icon {
  position: absolute;
  top: 10px;
  left: themeAttrVal(v4-fz-12);
  color: themeAttrVal(v4-second-text-color);
}
.m-login-psw {
  flex-shrink: 0;
  width: 200px;
  position: relative;
}
.m-login-btn {
  height: 30px;
  line-height: 20px;
  margin: 0 auto;
  font-size: themeAttrVal(v4-fz-14);
  //background-color: #0f3145 !important;
  //color: #f0f3f5 !important;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  button {
    width: 150px;
    height: 36px;
    transition: all ease-in-out 0.1s;
    background-color: themeAttrVal(v4-main-color);
    color: themeAttrVal(v4-second-text-color);
    &:hover {
      background-color: themeAttrVal(v4-dark-main-color);
    }
  }

  button.cancel-btn {
    background-color: transparent;
    border-color: themeAttrVal(v4-main-color);
  }
}

footer.m-login-btn {
  margin: 20px 0 24px;

  button {
    // width: 110px;
    // height: 30px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.m-login-msg {
  margin: 11px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 17px;
  font-size: themeAttrVal(v4-fz-12);
  color: themeAttrVal(v4-third-text-color);
  text-align: center;
}

.m-test {
  margin: 0;
}

.m-login-bottom span {
  padding-right: 20px;
}

.pwd-visible-icon {
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
  width: 24px;
  text-align: center;
}
//}
//QR Code
.status-wrap {
  & > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span:first-child {
      margin-right: 10px;
    }
  }

  .status-2fa.disabled {
    color: #fd4d48;
  }

  .status-2fa.enabled {
    color: #8cc63f;
  }
  height: 400px;
}

header {
  padding-bottom: 40px;
}

// main {
//   display: flex;

//   img {
//     margin-right: 20px;
//     height: 244px;
//   }

//   .gray {
//     color: #757a78;
//   }

//   p {
//     margin-bottom: 16px;
//   }

//   .row {
//     p:first-child {
//       margin-bottom: 6px;
//     }
//   }
// }

.auth-red {
  cursor: pointer;
  color: red;
}

.submit {
  margin-top: 30px;
  text-align: center;

  button {
    width: 48%;
    font-size: 14px;
  }

  button:first-child {
    margin-right: 4%;
  }
}
//2FA OTP
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  width: 540px;
}
