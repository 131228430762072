.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:100px;
  height:100px;
  background:transparent;
  border:3px solid #8e8d8d;
  border-radius:50%;
  text-align:center;
  line-height:80px;
  font-family:sans-serif;
  font-size:10px;
  color:#10b12d;
  letter-spacing:2px;
  text-transform:uppercase;
  text-shadow:0 0 10px #85ed66;
  box-shadow:0 0 20px #dfdf82;//#00000080;
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #23a409;
  border-right:3px solid #23a409;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.span:before
{
  content:'';
  position:absolute;
  width:10px;
  height:10px;
  border-radius:50%;
  background:#03570f;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #fff000;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}