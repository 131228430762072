.contant-wrap {
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 8px 32px 0 rgba(224, 224, 232, 0.33);
  margin-left: 10px;
  margin-right: 10px;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000e0;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  .ant-col-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.title {
  color: #26910e;
  font-size: 12px;
  font-weight: 500;
  //padding-top: 40px;
}
.title1 {
  color: #000000e0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #333736;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.flex {
  display: flex;
}
.weight-600 {
  font-weight: 800;
}
#tbl-cell {
  border: 1px solid #2f5369;
  border-radius: 10px;
  padding: 10px;
  background: #caddea;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .ant-col-header {
    padding: 4px;
    color: #000;
    font-size: 12px;
  }
  .ant-col {
    background: #9cbbd0;
    border: 1px solid #f1efef;
    font: 1em sans-serif;
    padding: 4px;
    color: #172c39;
  }
  .ant-col-2 {
    text-align: center;
  }
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    //padding: 4px 11px;
    height: 20px;
    background-color: #b9cad6;
    background-image: none;
    border: 1px solid #2f5369;
    border-radius: 4px;
    transition: all 0.2s;
  }
  .ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    padding: 0px 8px;
    font-size: 9px !important;
    background-color: #b9cad6 !important;
    .ant-input {
      border: none;
      background-color: #b9cad6;
    }
    .ant-input-suffix {
      margin-inline-start: 4px;
      font-size: 9px;
    }
  }
  .wt-100 {
    width: 800px;
  }
  .text-center {
    text-align: center;
    span {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .ant-select-single {
    font-size: 10px;
    height: 22px;
    span {
      //color: #b30707 !important;
      font-weight: 600;
      font-size: 10px;
    }
    .ant-select-selection-item {
      padding-inline-end: 10px;
      font-size: 10px;
    }
  }
  .ant-select-selector {
    position: relative;
    background-color: #b9cad6 !important;
    border: 1px solid #175413 !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.tbl-title {
  h5 {
    margin: 2px;
    font-weight: 700;
    font-size: 14px;
    color: #2f5369;
  }
}

.ant-card-head {
  border-bottom: 1px solid #4f0e0e;
  background: #9cbbd0 !important;
}
.ant-card-head-title {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #2f5369;
  text-transform: uppercase;
}
.ant-btn-primary {
  color: gray;
  background-color: #e9ede9;
  border: 1px solid #26910e;
  font-size: 14px;
  box-shadow: 0 2px 0 #0591ff1a;
  &:hover {
    background-color: #26910e;
  }
}
.example {
  height: 64vh;
}
.knowldge-action .ant-btn:last-child {
  width: 62px;
  /* border-top-right-radius: 30px; */
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
}
.knowldge-action .ant-btn:first-child {
  display: none;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  .ant-col-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.title {
  color: #26910e;
  font-size: 12px;
  font-weight: 500;
  //padding-top: 40px;
}
.title1 {
  color: #000000e0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #333736;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.title-header {
  //display: flex;
  //line-height: 1.2714285714285714;
  //margin-top: 0;
  color: #515251;
  font-weight: 700;
  font-size: 14px;
  span {
    margin-left: 2px;
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }
  div {
    margin-top: 1px;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    justify-items: center;
  }
}
.ant-form-item .ant-form-item-control-input-content {
  flex: auto;
  max-width: 100% !important;
}

:where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-form"]
  [class*=" ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-form"]
  [class*=" ant-form"] {
  box-sizing: border-box;
  width: 100%;
}

//=============================
.col-center {
  font-size: 12px;
  font-weight: 700;
}
.main-title-header {
  line-height: 1.2714285714285714;
  margin: 5px;
  color: #000;
  //background: rgb(13, 13, 126);
  align-items: center;
  font-size: 14px;
  div {
    margin-right: 5px;
    font-weight: 800;
  }
  span {
    font-weight: 700;
    font-size: 10px;
  }
}

.ant-form-item .ant-form-item-control-input-content {
  flex: auto;
  max-width: 100% !important;
}

:where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-form"]
  [class^="ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-form"]
  [class*=" ant-form"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-form"]
  [class*=" ant-form"] {
  box-sizing: border-box;
  width: 100%;
}
//----------------------
.menu ul {
  list-style-type: none;
  margin: 10px;
  padding: 0;
  display: flex;
}

.mainbutton {
  border: 1px solid #26910e;
  padding: 10px;
  border-radius: 5px;
}
.flex-container1 {
  display: flex;
  flex-wrap: wrap;
  //background-color: rgb(171, 177, 177);
}

.flex-container1 > div {
  background-color: #f1f1f1;
  width: 167px;
  margin: 8px;
  text-align: center;
  line-height: 80px;
  font-size: 10px;
  border: 1px solid #26910e;
  border-radius: 2px;
  a {
    color: #494a4c !important;
  }
  h2 {
    color: #890b0b !important;
  }
}
.flex-container1 > div:hover {
  color: #f1f1f1 !important;
  background-color: #e2e1e1;
  a {
    color: #26910e !important;
  }
}
.flex-container1 .finalsubmit {
  border: 1px solid #26910e;
  background-color: #81c48a; //#2f5369;
  color: #fff;
}

@media (max-width: 800px) {
  .flex-container1 {
    flex-direction: column;
  }
}
