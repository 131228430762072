.myBox {
  text-align: center;
  border-bottom: 2px solid transparent;
  border-bottom: 2px solid linear-gradient(to right, #00ff00 0%, #009900 100%) !important;
  //border-image: linear-gradient(0.25turn, rgba(255,249,34), rgba(255,0,128), rgba(56,2,155,0));
  border-image-slice: 1;
  width: 100%;
}
.nav-rightmenu {
  background-color: "gray";
  top: "-5px";
  color: "#26910e";
  .anticon svg {
    display: inline-block;
    color: rgb(27, 97, 6); //aliceblue;
  }
}
.nav-rightmenu :hover {
  background-color: "red";
  .anticon svg :hover {
    display: inline-block;
    color: rgb(97, 36, 6); //aliceblue;
  }
}

.ant-navbar .ant-menu-title-content {
  transition: color 0.3s;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600;
}
.ant-layout-header {
  line-height: 48px;
  background: #fff !important;
}
.ant-popover .ant-popover-title {
  min-width: 177px;
  margin-bottom: 8px;
  color: rgba(2, 34, 61, 0.88);
  font-weight: 600;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after {
  border-bottom-width: 2px;
  border-bottom-color: transparent; //#ff6b16;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #054403 !important;
  background-color: transparent; //#86a3bf !important;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, #92f253, #035f02, #849a0700);
  border-image-slice: 1;
}
.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-open::after {
  border-bottom-width: 2px;
  // border-bottom-color: #ff2d16 !important;
  border-bottom-color: transparent !important;
}
//===============================================================
.profilefile {
  border-top: 1px solid #a1a8a5;
  //width: 100%;
  max-height: 110px;
  padding-left: 12px;
  background-color: transparent;
  a {
    color: #080102;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
}
.profilefile-item :hover {
  background-color: #a1a8a5;
  color: #000;
}
