.person-2fa-wrap {
  .status-wrap {
    & > div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span:first-child {
        margin-right: 10px;
      }
    }

    .status-2fa.disabled {
      color: #fd4d48;
    }

    .status-2fa.enabled {
      color: #8cc63f;
    }
    height: 400px;
  }
  .code-wrap {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 58px 15px 102px;
    // padding-left: 40px;
    // margin: 9%;
    // width: 626px;
    //height: 400px;
    //background-color: rgb(67, 65, 65);

    header {
      padding-bottom: 40px;
    }

    main {
      display: flex;

      img {
        margin-right: 20px;
        height: 244px;
      }

      .gray {
        color: #757a78;
      }

      p {
        margin-bottom: 16px;
      }

      .row {
        p:first-child {
          margin-bottom: 6px;
        }
      }
    }
    // display: flex;
    // padding-top: 20px;
    // justify-content: center;

    .auth-red {
      cursor: pointer;
      color: red;
    }

    .submit {
      margin-top: 30px;
      text-align: center;

      button {
        width: 48%;
        font-size: 14px;
      }

      button:first-child {
        margin-right: 4%;
      }
    }

    & > div:first-child {
      margin: 0 50px;
    }
  }
}

.app-qrcode-wrap {
  display: flex;
  justify-content: space-around;

  section {
    text-align: center;
    width: 100px;

    &:first-child {
      margin-right: 10px;
    }

    img {
      width: 100%;
    }

    span {
      margin-top: 10px;
      font-size: 16px;
    }
  }
}

.ant-tooltip .ant-tooltip-inner {
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #000000d9;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 #00000014, 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px #0000000d;
  box-sizing: border-box;
}
