.logo {
  //width: 100%;
  width: 200px;
  height: 50px;
  margin-right: 34px;
}
.logo-large {
 
  margin-right: 34px;
  border-bottom: 2px solid transparent;
  border-image-slice: 1;
  
}
.myBox {
  text-align: center;
  border-bottom: 2px solid transparent;
  //border-bottom: 2px solid linear-gradient(to right, #00ff00 0%, #009900 100%) !important;
  border-image-slice: 1;
  width: 100%;
}
.nav-wrapper {
  position: relative;
  padding-inline: 0px;
  display: flex;
  align-items: right;
  height: 50px;
  overflow: hidden;
  box-sizing: border-box;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 556px;
  background: #f5f5f5;
}
.ant-navbar .ant-menu-title-content {
  transition: color 0.3s;
  //color: #198116 !important;
  font-weight: 600;
  font-size: 14px !important;
}
.ant-layout .ant-layout-sider {
  position: relative;
  margin-top: 10px;
  background: #fff !important;
  color: #035f02 !important;
  transition: all 0.2s, background 0s;
  border-right: 1px solid #035f02;
}
.ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #035f02 !important;
}
.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background-color: transparent !important;
  border-top: 1px solid #035f02;
  font-size: 14px;
  font-weight: 600;
  color: #035f02 !important;
  border-bottom: 2px solid;
  border-image: linear-gradient(0.25turn, #929a0700, #035f02, #849a0700);
  border-image-slice: 1;
}
.ant-menu-light,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light > .ant-menu {
  //color: #7ebc5f;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background: #fff !important;
}

.table-title-header-top {
  display: flex;
  justify-content: space-between;
  //border-left: 6px solid #333736;
  //line-height: 1.2714285714285714;
  margin-bottom: 5px;
  align-items: end;
  h2 {
    margin-left: 4px;
    margin-bottom: 0;
    color: #333736;
    font-weight: 800;
    font-size: 18px;
  }
  button {
    color: #247504 !important;
    font-weight: 600 !important;
    font-size: 10px !important;
  }
}
.ant-menu-submenu-popup {
  margin-left: -50px;
  padding-left: 0px;
  max-height: 300px;
  overflow: auto;

  --arrow-x: 10px;
  --arrow-y: 20px;
  inset: 244.667px auto auto 281.089px;
  box-sizing: border-box;
}
.ant-menu-light.ant-menu-submenu > .ant-menu {
  background: #e6e5e5;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: #000;
  font-size: 12px;
  line-height: 1.5714285714;
  list-style: none;
  background-color: #ffff;
  background-image: none;
  border: 1px solid #0b5809;
  border-radius: 6px;
  transition: all 0.2s;
  //box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  &:hover {
    border-color: #41b905;
    background-color: #ffffff;
  }
}

.textarea {
  font-size: inherit;
  border: none;
  outline: none;
  border: 1px solid red;
}

.ant-input-textarea-show-count > .ant-input {
  height: 100%;
  border: 1px solid red;
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 12px 0;
  height: 1px;
  background: #b4c7b4;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000e0;
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
}
//==== DDL Css
.ant-select-single {
  font-size: 14px;
  height: 32px;
  width: 235px;
}
.ant-select-selector {
  position: relative;
  background-color: #fff !important;
  border: 1px solid #175413 !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-input-search .ant-input-search-button {
  border: 1px solid #175413;
  height: 28px;
  background-color: #4d504d;
}
//===========================================
//@import url(.);
.f-table-wrapper1 {
  .ant-table-wrapper table {
    border-spacing: 2px;
  }
  .ant-table-wrapper .ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    //color: #a1a8a5;
    font-size: 14px;
    line-height: 20px;
    list-style: none;
    border-radius: 8px 8px 0 0;
    font-family: Arial, Verdana, sans-serif;
    a {
      color: var(--green);
      font-weight: 500;
      background-color: transparent;
      cursor: pointer;
      transition: color 1.3s;
      &:hover {
        //color: #4a0a07;
        color: var(--red);
      }
    }
  }
  tr:nth-child(odd) {
    background: #d0dfdb;
  }
  tr:nth-child(even) {
    background: #e5e8e8;
    color: #000;
  }
  thead[class*="ant-table-thead"] th {
    background-color: #265050 !important;
    color: white !important;
    font-weight: bold;
    border-color: #000;
    text-align: center;
  }
  .table_btn {
    margin: 0 !important;
  }
  .ant-btn {
    margin: 0;
  }
}
//=================== ref =====================
.knowldge-action {
  .ant-btn {
    width: 65px;
    background-color: transparent;
    border-radius: 0px;
    height: 30px;
    border-color: themeAttrVal(v4-b8);
    &:first-child {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      &:hover,
      &:active,
      &:focus {
        border-color: themeAttrVal(v4-border-3);
        color: themeAttrVal(v4-other-text-color);
      }
    }
    &:last-child {
      width: 31px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      &.ant-dropdown-open {
        border-color: themeAttrVal(v4-border-3);
        .anticon svg path {
          color: themeAttrVal(v4-other-text-color);
        }
      }
    }
  }
  .ant-btn[disabled] {
    border-color: themeAttrVal(v4-b8);
    color: themeAttrVal(v4-primary-text-color);
    &:hover,
    &:active,
    &:focus {
      border-color: themeAttrVal(v4-b8);
      color: themeAttrVal(v4-primary-text-color);
    }
  }
}
.f-table-wrapper {
  .ant-table {
    font-size: 12px; //$table_fz;
    background-color: #000; //$table_bg;
  }
  .ant-table-thead > tr > th {
    color: #fff; //$thead_c;
    font-size: 12px; //$thead_fz;
    border-color: #fff; //$thead_border-color;
    background-color: #17384e;//#265050; //$thead_bg !important;
    &::after {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 6px;
      height: 1.6em;
      //background-color: #808080;//$thead_bg !important;
      transform: translateY(-50%);
      transition: background-color 0.2s;
      content: "";
    }
    &.ant-table-column-has-sorters .ant-table-column-sorters {
      padding: 0px;
    }
    &.ant-table-column-has-sorters
      .ant-table-column-sorter-inner
      .active
      svg
      path {
      color: themeAttrVal(v4-main-color);
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px 5px 15px;
    height: 45px;
    // @if($theme-name == 'white') {
    //     border: none;
    // }
    //line-height: 30px;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background-color: transparent;
  }
  .ant-table-tbody > tr.ant-table-row > td {
    background-color: #9cbbd0;//#d0dfdb; //rgba(themeAttrVal(v4-h2bg-2), .2);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #8fb6cf; // rgba(themeAttrVal(v4-h2bg-2), .2);
    transition: all ease-in-out 0.5s;
    border-color: #fff; //$thead_border-color;
  }

  .ant-table-tbody > tr {
    &:nth-child(odd) {
      background-color: #d0dfdb; // themeAttrVal(v4-bg-11);
    }
    &:nth-child(even) {
      background-color: #e5e8e8; //themeAttrVal(v4-bg-12);
    }
    > td {
      border-color: #fff; //$td_border-color;
      color: themeAttrVal(v4-second-text-color);
      // @if($theme-name == 'white') {
      //     color: themeAttrVal(v4-third-text-color);
      // }
    }
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: none;
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    padding: 0;
    background-color: transparent;
  }

  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin-left: 0;
  }

  .table-expanded-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    transition: all ease-in-out 0.2s;

    &::before {
      vertical-align: middle;
    }

    &.table-expaneded-icon-expanded {
      color: red; //$expanded-icon-expanded-color;
      transform: rotate(90deg);
    }
  }

  .ant-table-expanded-row .ant-table {
    top: 10px;
    border-top: 1px solid themeAttrVal(v4-border-7);
    border-bottom: 1px solid themeAttrVal(v4-border-7);
    tbody tr {
      background-color: red; //rgba($expand_row_bg, .2);
    }

    thead tr th {
      background-color: red; //rgba($expand_row_bg, .2);
    }
  }

  th.ant-table-column-has-sorters .ant-table-column-sorters {
    padding: 0px;
  }

  td.ant-table-column-sort {
    background-color: transparent;
  }

  .anticon.active path {
    color: themeAttrVal(v4-bg-4);
  }
  .ant-btn-link {
    //margin-top: 10px;
    border: none;
    //font-weight: 200 !important;
    font-size: 10px !important;
    //box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    span {
      color: #751b04;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
//=============================================
.ant-btn-link {
  //margin-top: 10px;
  //border: none;
  color: #247504 !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-btn {
  //margin-top: 10px;
  border-color: #247504;
  color: #247504 !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-btn:hover {
  color: #5b0505 !important;
  border-color: #5b0505 !important;
}
.ant-btn .cancel {
  border-color: #5b0505 !important;
  color: #5b0505 !important;
}

.ant-btn-download {
  margin: 0px;
  padding: 0px;
  border: none;
  //border-color: #5b0505 !important;
  color: #247504 !important;
}
