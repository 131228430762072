.container-customer-dashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-image: url("../../../static/images/customer-dashboard/image1.jpg"); /* The image used */
  background-size: cover;
}

.column {
  flex: 1;

}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the left column takes full height */
}

.logo-section {
  text-align: left;
}

.dashboard-logo {
  width: 300px; /* Adjust the width to make the images smaller */
  height: 100px; /* Adjust the height to make the images smaller */
  margin-bottom: 20px;
 
}

.logo-text {
    font-size: 50px;
    font-weight: bolder;
    color: rgb(239, 239, 239); /* Set the text color to blue */
}

.labels-section {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 100px;
}

.label {
  background-color: rgb(239, 239, 239); /* Set the background color to white */
  color: black; /* Set the text color to black */
  border: 2px solid rgb(106, 182, 220); /* Add a black border */
  padding: 5px 10px;
  border-radius: 12px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: bold; /* Make the text bold */
  white-space: nowrap;
}

.right-column {
  display: flex;
  justify-content: right;
  align-items: left;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 580px; /* Adjust the width to make the images smaller */
  height: 300px; /* Adjust the height to make the images smaller */

}

.button-label {
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.grid-image {
  //max-width: 100%;
  // height: 245px;
  width: 280px;
  height: 250px;
}
.container {
  font-family: arial;
  font-size: 24px;
  width: 100%;
  height: 100%;
  outline: dashed 1px black;
  /* Setup */
  position: relative;
}

.child {
  width: 250px;
  height: 50px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-container {
  display: flex;
  justify-content: space-between;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
//==========================================

.ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #fff !important;
  background-clip: padding-box;
  border-radius: 8px;
  border-bottom: 1px solid #808080;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  border-bottom: 1px solid #e7e4e4;
  border-radius: 0;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  color: #247504 !important;
}

.ant-btn1 {
  font-size: 14px !important;
  height: 20px;
  /* padding: 4px 15px; */
  border-radius: 6px;
  margin-top: -25px;
  span {
    font-size: 10px !important;
    padding: 2px;
    color: #4f0e0e;
  }
}

.ant-card-bordered {
  border: 1px solid #4f0e0e;
  .ant-card-head {
    border-bottom: 1px solid #4f0e0e;
  }
}

.logoutBtn {
  height: "30px";
  width: "100px";
}
.anticon svg {
  display: inline-block;
  color: aliceblue;
}
.dashboardPage {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 50%;
}


.ant-select-disabled:where(.css-dev-only-do-not-override-dkbvqv).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black;
  background: rgba(0, 0, 0, 0.04) !important;
  cursor: not-allowed;
}
