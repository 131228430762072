.ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #efefee;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #0b5809;
  border-radius: 6px;
  transition: all 0.2s;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: #efefee;
  font-size: 12px;
}
.ant-form-item .ant-form-item-label {
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  vertical-align: middle;
  width: 150px !important;
}
