.ant-comment {
  position: relative;
  background-color: inherit;
  .ant-comment-inner {
    display: flex;
    padding: 16px 0;
    .ant-comment-avatar {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-right: 12px;
      cursor: pointer;
    }
    .ant-comment-content {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-width: 1px;
      font-size: 14px;
      word-wrap: break-word;
    }
  }
  .ant-comment-content-author-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 4px;
    font-size: 14px;
    span {
      padding-right: 8px;
      font-size: 12px;
      line-height: 18px;
    }
    .ant-comment-content-author-name {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }
    .ant-comment-content-author-time {
      color: #a4a3a3;
      white-space: nowrap;
      cursor: auto;
    }
  }
  .ant-comment-content-author-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 4px;
    font-size: 14px;
    span {
      padding-right: 8px;
      font-size: 12px;
      line-height: 18px;
    }
    .ant-comment-content-author-name {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }
    .ant-comment-content-author-time {
      color: #a4a3a3;
      white-space: nowrap;
      cursor: auto;
    }
  }

  .ant-comment-content-left {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
    color: #545353;
    p {
      margin-top: 0;
      margin-bottom: 1em;
    }
    .ant-comment-content-detail p {
      white-space: pre-wrap;
    }
  }
  .ant-comment-content-right {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 1px;
    font-size: 14px;
    word-wrap: break-word;
    color: #545353;
    text-align: right;
    p {
      margin-top: 0;
      margin-bottom: 1em;
    }
    .ant-comment-content-detail p {
      white-space: pre-wrap;
    }
  }
}
.comment-align {
  width: 100%;
  display: flex;
  justify-content: end;
}
.ant-comment-count {
  cursor: pointer;
  color: #04355e !important;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: end;
  width: auto;
}
.ant-comment-modalcount {
  width: 90px;
  cursor: pointer;
  color: #450608 !important;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}
//======================
.textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.textarea-container button {
  position: absolute;
  bottom: 0;
  right: 0;
  background: transparent;
  color: #25382b !important;
  border: none;
}
//=================================
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.02);
  border: none;
}
//=======================================
#sticky-chat {
  .ant-popconfirm-buttons {
    text-align: end;
    white-space: nowrap;
    display: none !important;
  }
}
.float{
	position:fixed;
	//width:100px;
	//height:30px;
	bottom:210px;
	right:15px;
	background-color:orangered;
	color:red;
	border-radius:5px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}