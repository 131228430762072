.flex-container {
  display: flex;
  justify-content: space-between;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
.ant-btn1 {
  font-size: 14px !important;
  height: 25px;
  border-radius: 6px;
  margin-top: 0px !important;
}
.modalform {
  width: calc(100% - 60px);
}
//==========================================
.logoutBtn {
  height: "30px";
  width: "100px";
}

.dashboardPage {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-form1 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
  width: 698px;
}
