.flex-container {
  display: flex;
  justify-content: space-between;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
//==========================================
.logoutBtn {
  height: "30px";
  width: "100px";
}

.dashboardPage {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 50%;
}
//====================================================
.ant-form-item .ant-form-item-label {
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  vertical-align: middle;
  width: 50px;
}
.ant-select-single {
  font-size: 14px;
  height: 28px;
  width: 235px;
}
.year-month {
  width: 460px;
  margin-left: 20px;
  margin-right: 38px;

  .ant-form-item .ant-form-item-label {
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
    vertical-align: middle;
    width: 300px !important;
  }
}
.year {
  width: 123px;
  margin-left: 30px;
  border: 1px solid #0b5809;
}
